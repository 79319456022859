import React, { FC, memo, useContext, useMemo, useRef, useState } from 'react';
import {
   Autocomplete,
   Button,
   Chip,
   Grid,
   Input,
   MenuItem,
   Select,
   SelectChangeEvent,
   Slider,
   Switch,
   TextareaAutosize,
   TextField,
} from '@mui/material';
import { FormikProps } from 'formik';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
   SessionTemplate,
   TemplateSupportedTheme,
   TemplateType,
} from '../../../../../../consts/types/templates';
import { SessionTemplateCategory } from '../../../../../../consts/types/categories';
import env from '../../../../../../config/env';
import { createBiLogger } from '../../../../../../APIs/BI';
import { SessionTemplateDataContext } from '../../../../../../providers/sessionTemplates/SessionTemplateDataProvider';
import { sessionTemplatesAPI } from '../../../../../../APIs/firebase/sessionTemplatesAPI';
import { defaultAudienceSize } from '../../../../../../APIs/fbConvertor';
import FcTemplatesOfCategory from '../../../../../UI/CategoriesWithTemplates/TemplatesOfCategory/TemplatesOfCategory';
import { SessionTemplatesContext } from '../../../../../../providers/sessionTemplates/SessionTemplatesProvider';
import { reorderArr } from '../../../../../../utils/array';
import { FcTierRestrictionsSelection } from '../../../../common/TierRestrictionSelection/TierRestrictionSelection';
import css from './SessionTemplateFormFields.module.scss';

const bi = createBiLogger('Template-Form-API');

interface Props {
   formik: FormikProps<SessionTemplate>;
   themesNames?: Map<string, string>;
}

const initialTheme = (supportedThemes?: TemplateSupportedTheme[]) => {
   return supportedThemes?.length ? supportedThemes[0].themeId : '';
};

const FcSessionTemplateFormFields: FC<Props> = (props) => {
   const { formik, themesNames } = props;
   const { tags, badges, addTag } = useContext(SessionTemplateDataContext);
   const { sessionTemplates, sessionTemplatesList } = useContext(SessionTemplatesContext);

   const initialTemplates = () => {
      if (!formik.values.relatedTemplatesIds) return [];
      return formik.values?.relatedTemplatesIds.map(
         (templateId: string) => sessionTemplates[templateId]
      );
   };

   const { createdAt, id, sessionId } = formik.initialValues;
   const [currentTheme, setCurrentTheme] = useState(initialTheme(formik.values.supportedThemes));
   const relatedTemplates = useRef<SessionTemplate[]>(initialTemplates());
   const [hasAudience, setHasAudience] = useState(!!formik.values?.audienceSize);

   const handleChange = (event: SelectChangeEvent) => {
      setCurrentTheme(event.target.value);
   };

   const handleSliderChange = (value: number | number[]) => {
      // to enable range mode for the slider, we must pass an array of numbers
      if (Array.isArray(value)) {
         formik.setFieldValue('audienceSize', {
            from: value[0],
            to: value[1],
         });
      } else {
         bi.error('Expect an array of numbers and got a number');
      }
   };

   const onUpdate = async () => {
      try {
         await sessionTemplatesAPI.updateTemplate(formik.values.id);
         alert('Update template successfully!');
      } catch (err) {
         alert('Failed to update template');
      }
   };

   const getCurThemeIndex = (themeId?: string): number => {
      const themeIndex = formik.values.supportedThemes?.findIndex(
         (supportedTheme) => supportedTheme.themeId === themeId
      );
      return themeIndex && themeIndex !== -1 ? themeIndex : 0;
   };

   const currentThemeIndex = getCurThemeIndex(currentTheme);

   const setFormikRelatedTemplatesIds = () => {
      formik.setFieldValue(
         'relatedTemplatesIds',
         relatedTemplates.current.map((t) => t.id)
      );
   };

   const handleTemplateClick = (templateId: string) => {
      relatedTemplates.current = [...relatedTemplates.current, sessionTemplates[templateId]];
      setFormikRelatedTemplatesIds();
   };

   const handleTemplateDelete = (templateId: string) => {
      relatedTemplates.current = relatedTemplates.current.filter((item) => item.id !== templateId);
      setFormikRelatedTemplatesIds();
   };

   const handleTemplateDrag = (result: DropResult) => {
      if (!result.destination) return;
      const indexFrom = result.source.index;
      const indexTo = result.destination.index;
      relatedTemplates.current = reorderArr(relatedTemplates.current, indexFrom, indexTo);
      setFormikRelatedTemplatesIds();
   };

   const memoizedBadgesList = useMemo(
      () =>
         badges.map((badge) => (
            <MenuItem key={badge.id} value={badge.id} className={css.badgeSelect}>
               <span
                  className={css.colorPreview}
                  style={{
                     background: badge.color,
                  }}
               />
               {badge.name}
            </MenuItem>
         )),
      [badges]
   );

   const relatedCategory: SessionTemplateCategory = {
      id: 'related',
      name: 'Related',
      templatesIds: relatedTemplates.current.map((item) => item.id),
   };

   return (
      <div className={css.inputsContainer}>
         <Grid container alignItems="center" gap={2}>
            <label>Name:</label>
            <Input
               className={css.input}
               id="name"
               required
               onChange={formik.handleChange}
               value={formik.values.name}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Image URL:</label>
            <Input
               className={css.input}
               id="imgURL"
               onChange={formik.handleChange}
               value={formik.values.imgURL}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Video URL:</label>
            <Input
               className={css.input}
               id="videoURL"
               onChange={formik.handleChange}
               value={formik.values.videoURL}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Visible:</label>
            <Switch
               itemID="isVisible"
               checked={formik.values.isVisible}
               onChange={(_, value) => formik.setFieldValue('isVisible', value)}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Themeable:</label>
            <Switch
               itemID="isThemeable"
               checked={formik.values.isThemeable}
               onChange={(_, value) => formik.setFieldValue('isThemeable', value)}
            />
         </Grid>

         <Grid container gap={2}>
            <label>Description:</label>
            <TextareaAutosize
               id="description"
               minRows={4}
               maxRows={4}
               className={css.textarea}
               value={formik.values.description}
               onChange={formik.handleChange}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label htmlFor="audience-size-slider">Audience Size:</label>
            <Switch
               itemID="hasAudience"
               checked={hasAudience}
               onChange={(_, value) => {
                  setHasAudience(value);
                  if (!value) {
                     formik.setFieldValue('audienceSize', {});
                  }
               }}
            />
            {hasAudience && (
               <div className={css.sliderContainer}>
                  <Slider
                     name="audience-size-slider"
                     min={2}
                     max={200}
                     value={[
                        formik.values.audienceSize?.from ?? defaultAudienceSize.from,
                        formik.values.audienceSize?.to ?? defaultAudienceSize.to,
                     ]}
                     onChange={(_, value) => handleSliderChange(value)}
                     valueLabelDisplay="auto"
                     aria-labelledby="audience-size-slider"
                  />
                  <span className={css.sliderValue}>
                     {`${formik.values.audienceSize?.from ?? defaultAudienceSize.from}-${
                        formik.values.audienceSize?.to ?? defaultAudienceSize.to
                     }`}
                  </span>
               </div>
            )}
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Badge</label>
            <Select
               id="template-badge"
               displayEmpty
               value={formik.values.badgeId ?? ''}
               onChange={(event) => formik.setFieldValue('badgeId', event.target.value)}
               classes={{
                  select: css.badgeSelect,
               }}
               sx={{
                  minWidth: '200px',
                  margin: '12px 0',
               }}
            >
               <MenuItem value="">None</MenuItem>
               {memoizedBadgesList}
            </Select>
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Tags</label>
            <Autocomplete
               multiple
               id="tags-filled"
               value={formik.values?.tags}
               options={tags.map((tag) => tag)}
               freeSolo
               limitTags={2}
               onChange={(_, value, reason) => {
                  if (reason === 'createOption') {
                     addTag(value[value.length - 1]);
                  }
                  formik.setFieldValue('tags', [...value]);
               }}
               renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                     // eslint-disable-next-line react/jsx-key
                     <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
               }
               renderInput={(params) => <TextField {...params} placeholder="Start typing..." />}
               sx={{ flex: '1 1 0%' }}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>ID:</label>
            <p className={css.input}>{id}</p>
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Session ID:</label>
            <p className={css.input}>{sessionId}</p>
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Link:</label>
            <div className={css.input}>
               <a
                  href={`https://${env.appDomain}/editor/${sessionId}`}
                  target="_blank"
                  rel="noreferrer"
               >
                  {`${env.appDomain}/editor/${sessionId}`}
               </a>

               <Button
                  color="secondary"
                  variant="outlined"
                  size={'small'}
                  className={css.btn}
                  onClick={onUpdate}
               >
                  Update
               </Button>
            </div>
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Created At:</label>
            <p className={css.input}>{new Date(createdAt).toUTCString()}</p>
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Template in pool:</label>
            <Switch
               itemID="isTemplateInPool"
               checked={formik.values.isTemplateInPool}
               onChange={(_, value) => formik.setFieldValue('isTemplateInPool', value)}
            />
         </Grid>

         <FcTierRestrictionsSelection
            tierRestriction={formik.values.tierRestriction}
            onChange={(tierRestriction) => formik.setFieldValue('tierRestriction', tierRestriction)}
         />

         {!!formik.values.supportedThemes?.length && (
            <Grid container alignItems="center" gap={2}>
               <label>Theme preview url:</label>
               <Select value={currentTheme} onChange={handleChange}>
                  {formik.values.supportedThemes?.map((theme) => {
                     return (
                        <MenuItem key={theme.themeId} value={theme.themeId}>
                           {themesNames?.get(theme.themeId)}
                        </MenuItem>
                     );
                  })}
               </Select>
               <Input
                  name={formik.values.supportedThemes[currentThemeIndex].themeId}
                  type="text"
                  className={css.themeURL}
                  value={formik.values.supportedThemes[currentThemeIndex].videoPreviewUrl}
                  onChange={(e) => {
                     const supportedThemes = formik.values.supportedThemes ?? [];
                     supportedThemes[currentThemeIndex].videoPreviewUrl = e.target.value;
                     formik.setFieldValue('supportedThemes', supportedThemes);
                  }}
               />
            </Grid>
         )}
         <DragDropContext onDragEnd={handleTemplateDrag}>
            <FcTemplatesOfCategory
               category={relatedCategory}
               categoryTemplates={relatedTemplates.current}
               templateType={TemplateType.SESSION}
               allTemplates={sessionTemplatesList}
               onItemClick={handleTemplateClick}
               onDeleteItem={handleTemplateDelete}
            />
         </DragDropContext>
      </div>
   );
};

export default memo(FcSessionTemplateFormFields);
