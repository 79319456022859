import env from '../../config/env';

const getServerBaseUrl = () => {
   if (window.location.hostname.includes('localhost')) {
      return 'https://' + env.firebase.functionsDomain;
   }
   return ''; //Relative path
};

const serverBaseUrl = getServerBaseUrl();

export { serverBaseUrl };
