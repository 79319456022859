import { doc, updateDoc, onSnapshot, addDoc, deleteDoc } from '@firebase/firestore';
import { FbSceneTemplatesCategory, SceneTemplateCategory } from '../../consts/types/categories';
import { fbConvertor } from '../fbConvertor';
import { createBiLogger } from '../BI';
import { db } from './index';

const bi = createBiLogger('scene-template-categories-API');

const sceneTemplateCategoriesAPI = {
   listenAll(
      onSceneTemplateCategoriesUpdate: (sceneTemplateCategories: SceneTemplateCategory[]) => void
   ) {
      const countLogger = bi.createCountLogger('all-scene-template-categories');
      onSnapshot(
         db.sceneTemplateCategories,
         (snapshot) => {
            countLogger.triggered();
            const sceneTemplateCategories: SceneTemplateCategory[] = [];
            snapshot.docs.forEach((item) => {
               const data = item.data();
               const sceneTemplateCategory = fbConvertor.sceneTemplateCategory(item.id, data);
               sceneTemplateCategories.push(sceneTemplateCategory);
            });
            onSceneTemplateCategoriesUpdate(sceneTemplateCategories);
         },
         countLogger.error
      );
   },

   async addSceneTemplateCategory(name: string) {
      const actionLogger = bi.createActionLogger('create-scene-template-category');
      try {
         const category: FbSceneTemplatesCategory = {
            name,
            templatesIds: [],
         };
         await addDoc(db.sceneTemplateCategories, category);
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },

   async updateSceneTemplateCategory(id: string, updates: Partial<SceneTemplateCategory>) {
      const actionLogger = bi.createActionLogger('update-scene-template-category');
      try {
         const document = doc(db.sceneTemplateCategories, id);
         await updateDoc(document, updates);
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },

   async deleteSceneTemplateCategory(id: string) {
      const actionLogger = bi.createActionLogger('delete-scene-template-category');
      try {
         const document = doc(db.sceneTemplateCategories, id);
         await deleteDoc(document);
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },
};

export { sceneTemplateCategoriesAPI };
