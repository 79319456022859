import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { WrapperComp } from '../../consts/types/general';
import { SessionTemplatesContext } from '../sessionTemplates/SessionTemplatesProvider';
import { SessionTemplateCategoriesStore } from './SessionTemplateCategoriesStore';
// @ts-ignore
const SessionTemplatesCategoriesContext = createContext<SessionTemplateCategoriesStore>();

const SessionTemplateCategoriesProvider: WrapperComp = (props) => {
   const { children } = props;
   const templatesStore = useContext(SessionTemplatesContext);
   const store = useMemo(() => new SessionTemplateCategoriesStore(templatesStore), []);

   useEffect(() => {
      store.initStore();
   }, []);

   return (
      <SessionTemplatesCategoriesContext.Provider value={store}>
         {children}
      </SessionTemplatesCategoriesContext.Provider>
   );
};

export { SessionTemplatesCategoriesContext, SessionTemplateCategoriesProvider };
