import React, { createContext, useEffect, useMemo } from 'react';
import { WrapperComp } from '../../consts/types/general';
import { SessionTemplatesStore } from './SessionTemplatesStore';
// @ts-ignore
const SessionTemplatesContext = createContext<SessionTemplatesStore>();

const SessionTemplatesProvider: WrapperComp = (props) => {
   const { children } = props;
   const store = useMemo(() => new SessionTemplatesStore(), []);

   useEffect(() => {
      store.initStore();
   }, []);

   return (
      <SessionTemplatesContext.Provider value={store}>{children}</SessionTemplatesContext.Provider>
   );
};

export { SessionTemplatesContext, SessionTemplatesProvider };
