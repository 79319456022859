import { initializeApp, FirebaseApp } from 'firebase/app';
import { getFirestore, Firestore } from 'firebase/firestore';
import { Auth, getAuth } from 'firebase/auth';
import env from './env';

let fbAppInstance: FirebaseApp | undefined;

const getAppInstance = (): FirebaseApp => {
   if (!fbAppInstance) {
      fbAppInstance = initializeApp(env.firebase);
      // if (env.useEmulator) {
      //    connectFirestoreEmulator(
      //        getFirestore(fbAppInstance),
      //        'localhost',
      //        8080
      //    );
      //    connectFunctionsEmulator(
      //        getFunctions(fbAppInstance),
      //        'localhost',
      //        5001
      //    );
      //    connectDatabaseEmulator(getDatabase(fbAppInstance), 'localhost', 9000);
      //
      //    connectStorageEmulator(getStorage(fbAppInstance), 'localhost', 9199);
      //
      //    connectStorageEmulator(
      //        getStorage(fbAppInstance, assetsBucketUrl),
      //        'localhost',
      //        9199
      //    );
      //
      //    connectAuthEmulator(getAuth(), 'http://localhost:9099', {
      //       disableWarnings: true,
      //    });
      // }
   }
   return fbAppInstance;
};

const getFirestoreInstance = (): Firestore => {
   return getFirestore(getAppInstance());
};

const getAuthInstance = (): Auth => {
   return getAuth(getAppInstance());
};

export { getFirestoreInstance, getAuthInstance };

// const firebaseConfig = {
//    apiKey: 'AIzaSyAS67CfFb2iIKyGSq8nbYd4tj-V5Vcbe-0',
//    authDomain: 'spotlight-dev-c132e.firebaseapp.com',
//    databaseURL:
//       'https://spotlight-dev-c132e-default-rtdb.europe-west1.firebasedatabase.app',
//    projectId: 'spotlight-dev-c132e',
//    storageBucket: 'spotlight-dev-c132e.appspot.com',
//    messagingSenderId: '597379445242',
//    appId: '1:597379445242:web:368c6eb556fd4ec3c0f443',
// };
