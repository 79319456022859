import React, { FC, memo } from 'react';
import { Button, Divider, Grid } from '@mui/material';

interface Props {
   onDelete?: () => void;
   deleteText?: string;
}

const FcFormButtons: FC<Props> = (props) => {
   const { onDelete, deleteText = 'delete' } = props;
   return (
      <>
         <Divider />
         <Grid
            container
            alignItems="center"
            justifyContent="end"
            gap="16px"
            sx={{ padding: '16px' }}
         >
            <Button color="secondary" variant="outlined" type="reset">
               Reset
            </Button>

            {onDelete && (
               <Button color="error" variant="outlined" onClick={onDelete}>
                  {deleteText}
               </Button>
            )}

            <Button color="secondary" variant="contained" type="submit">
               Save
            </Button>
         </Grid>
      </>
   );
};

export default memo(FcFormButtons);
