import React, { createContext, useEffect, useMemo } from 'react';
import { WrapperComp } from '../../consts/types/general';
import { UserStore } from './UserStore';
// @ts-ignore
const UserContext = createContext<UserStore>();

const UserProvider: WrapperComp = (props) => {
   const { children } = props;
   const userStore = useMemo(() => new UserStore(), []);

   useEffect(() => {
      userStore.initStore();
   }, []);

   return (
      <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
   );
};

export { UserContext, UserProvider };
