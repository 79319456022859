import React, { FC } from 'react';
import { SceneTemplate } from '../../../consts/types/templates';
import { getSceneTemplateEditorUrl } from '../../../utils/templateUtils';
import { FcTemplatePreview } from '../TemplatePreview/TemplatePreview';
import css from './AiGeneratableSceneTemplates.module.scss';

interface Props {
   aiSceneTemplatesList: SceneTemplate[];
}

const FcAiGeneratableSceneTemplates: FC<Props> = (props) => {
   const {aiSceneTemplatesList} = props;

   const onClick = (sceneTemplate: SceneTemplate) => {
      window.open(getSceneTemplateEditorUrl(sceneTemplate), '_blank');
   }

   return (
      <div className={css.category}>
         <h1>AI Generatable</h1>
         <div className={css.aiGeneratable}>
            {aiSceneTemplatesList.map((sceneTemplate, index) => (
               <a
                  key={index}
                  className={css.wrapper}
                  href={getSceneTemplateEditorUrl(sceneTemplate)}
                  target="_blank" rel="noreferrer"
               >
                  <FcTemplatePreview
                     rootCss={css.template}
                     template={sceneTemplate}
                     showHiddenBadge={false}
                     disabled={!sceneTemplate.aiEnabled}
                     onClick={() => onClick(sceneTemplate)}
                  />
               </a>
            ))}
         </div>
      </div>
   );
};

export { FcAiGeneratableSceneTemplates };
