import { Environment } from '../enviroment';

export const env: Environment = {
   name: 'STAGING',
   firebase: {
      apiKey: 'AIzaSyAhtqqASZNQBTLqiDmv3S5BSUL_PqvfVvE',
      authDomain: 'cms-stg.gloww.com',
      projectId: 'foreground-staging',
      storageBucket: 'foreground-staging.appspot.com',
      messagingSenderId: '258282897077',
      appId: '1:258282897077:web:e94273dff7f5ffb65208fc',
      measurementId: 'G-ET4KY5QX7X',
      functionsDomain: 'us-central1-foreground-staging.cloudfunctions.net',
      functionsRegion: 'us-central1',
      assetsBucket: 'foreground-staging-assets-storage',
   },
   appDomain: 'app-stg.gloww.com',
};
