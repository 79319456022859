import React, { FC, useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { SessionTemplate } from '../../../../../consts/types/templates';
import { SessionTemplateCategory } from '../../../../../consts/types/categories';
import FcFormButtons from '../../FormButtons/FormButtons';
import { SessionTemplatesContext } from '../../../../../providers/sessionTemplates/SessionTemplatesProvider';
import { sessionTemplatesAPI } from '../../../../../APIs/firebase/sessionTemplatesAPI';
import { fieldOperations, UpdateData } from '../../../../../APIs/firebase';
import FcMediaPreview from '../MediaPreview/MediaPreview';
import FcSessionTemplateFormFields from './SessionTemplateFormFields/SessionTemplateFormFields';

interface Props {
   template: SessionTemplate;
   closeDialog: () => void;
   onDeleteItem: (id: string) => void;
   category?: SessionTemplateCategory;
}

const FcSessionTemplateForm: FC<Props> = observer((props) => {
   const { template, closeDialog, onDeleteItem, category } = props;
   const { saveSessionTemplate } = useContext(SessionTemplatesContext);
   const [themesNames, setThemesNames] = useState<Map<string, string>>();

   const templateValues = { ...toJS(template) };

   if (templateValues.isTemplateInPool === undefined) {
      templateValues.isTemplateInPool = false;
   }

   useEffect(() => {
      if (templateValues.supportedThemes) getSystemThemes();
   }, []);

   const getSystemThemes = async () => {
      const themesNamesRes = await sessionTemplatesAPI.getThemesNames();
      setThemesNames(themesNamesRes);
   };

   const formik = useFormik({
      initialValues: templateValues,
      onSubmit: async (values) => {
         const valuesToSave: UpdateData<SessionTemplate> = { ...values };
         if (!values.isTemplateInPool && template.isTemplateInPool === undefined) {
            delete valuesToSave.isTemplateInPool;
         }
         if (values.audienceSize?.from === undefined) {
            valuesToSave.audienceSize = fieldOperations.delete;
         }
         if (values.badgeId === '') {
            valuesToSave.badgeId = fieldOperations.delete;
         }
         if (!values.tags || values.tags.length === 0) {
            valuesToSave.tags = fieldOperations.delete;
         }
         if (!values.relatedTemplatesIds || values.relatedTemplatesIds.length === 0) {
            valuesToSave.relatedTemplatesIds = fieldOperations.delete;
         }
         if (!values.tierRestriction) {
            valuesToSave.tierRestriction = 'Free';
         }
         try {
            await saveSessionTemplate(template.id, valuesToSave);
            closeDialog();
            alert('Edit template save successfully!');
         } catch (e) {
            alert('Failed to save edit template');
         }
      },
   });

   const onDelete = () => {
      if (!category) return;
      onDeleteItem(template.id);
      closeDialog();
   };

   return (
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} autoComplete="off">
         <Grid container>
            <FcMediaPreview imgURL={formik.values.imgURL} videoURL={formik.values.videoURL} />
            <FcSessionTemplateFormFields formik={formik} themesNames={themesNames} />
         </Grid>
         <FcFormButtons onDelete={category && onDelete} deleteText="Remove from category" />
      </form>
   );
});

export { FcSessionTemplateForm };
