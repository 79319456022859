import React, { createContext, useEffect, useMemo } from 'react';
import { WrapperComp } from '../../consts/types/general';
import { SceneTemplatesStore } from './SceneTemplatesStore';
// @ts-ignore
const SceneTemplatesContext = createContext<SceneTemplatesStore>();

const SceneTemplatesProvider: WrapperComp = (props) => {
   const { children } = props;
   const store = useMemo(() => new SceneTemplatesStore(), []);

   useEffect(() => {
      store.initStore();
   }, []);

   return <SceneTemplatesContext.Provider value={store}>{children}</SceneTemplatesContext.Provider>;
};

export { SceneTemplatesContext, SceneTemplatesProvider };
