import { useState, useCallback } from 'react';

const useToggle = (
   initialState = false
): [boolean, (value?: boolean | any) => void] => {
   const [toggleState, setToggleState] = useState<boolean>(initialState);

   const toggle = useCallback(
      (value?: boolean | any) =>
         setToggleState((s) => {
            return typeof value === 'boolean' ? value : !s;
         }),
      [setToggleState]
   );

   return [toggleState, toggle];
};

export { useToggle };
