import {
   collection,
   doc,
   CollectionReference,
   DocumentReference,
   deleteField,
   UpdateData,
} from '@firebase/firestore';
import { getFirestoreInstance } from '../../config/firebase';
import {
   FbSystemThemes,
   FbSessionTemplate,
   FbSceneTemplate,
   TemplateBadges,
   TemplateTags,
} from '../../consts/types/templates';
import {
   FbSceneTemplatesCategory,
   FbSessionTemplatesCategory,
} from '../../consts/types/categories';

enum DbCollections {
   SESSION_TEMPLATES = 'sessionTemplates',
   TEMPLATES_CATEGORIES = 'templatesCategories',
   TEMPLATES_CATEGORIES_WIP = 'templatesCategoriesWIP', // wip = work in progress
   SCENE_TEMPLATES = 'sceneTemplates',
   SCENE_TEMPLATE_CATEGORIES = 'sceneTemplateCategories',
   SYSTEM_THEMES = 'systemThemes',
   TEMPLATE_DATA = 'templateData',
}

const fieldOperations = {
   delete: deleteField(),
};

// enum DbDocs {}

type Collection<T> = CollectionReference<T>;
type Document<T> = DocumentReference<T>;
const firestore = getFirestoreInstance();

const db = {
   sessionTemplates: collection(
      firestore,
      DbCollections.SESSION_TEMPLATES
   ) as Collection<FbSessionTemplate>,
   templateBadges: doc(
      firestore,
      DbCollections.TEMPLATE_DATA,
      'templateBadges'
   ) as Document<TemplateBadges>,
   templateTags: doc(
      firestore,
      DbCollections.TEMPLATE_DATA,
      'templateTags'
   ) as Document<TemplateTags>,
   sessionTemplatesCategories: collection(
      firestore,
      DbCollections.TEMPLATES_CATEGORIES
   ) as Collection<FbSessionTemplatesCategory>,
   templatesCategoriesWIP: collection(
      firestore,
      DbCollections.TEMPLATES_CATEGORIES_WIP
   ) as Collection<FbSessionTemplatesCategory>,
   sceneTemplates: collection(
      firestore,
      DbCollections.SCENE_TEMPLATES
   ) as Collection<FbSceneTemplate>,
   sceneTemplateCategories: collection(
      firestore,
      DbCollections.SCENE_TEMPLATE_CATEGORIES
   ) as Collection<FbSceneTemplatesCategory>,
   adminsEmails: doc(firestore, 'admins', 'emails') as Document<{
      emails: string[];
   }>,
   systemThemes: collection(firestore, DbCollections.SYSTEM_THEMES) as Collection<FbSystemThemes>,
};

export type { UpdateData };
export { db, fieldOperations };
