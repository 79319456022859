import { makeAutoObservable } from 'mobx';
import { UpdateData } from '../../APIs/firebase';
import { SessionTemplate } from '../../consts/types/templates';
import { sessionTemplatesAPI } from '../../APIs/firebase/sessionTemplatesAPI';

class SessionTemplatesStore {
   private _sessionTemplates: Record<string, SessionTemplate> = {};

   constructor() {
      makeAutoObservable(this, {}, { autoBind: true });
   }

   get sessionTemplates() {
      return this._sessionTemplates;
   }

   set sessionTemplates(value: Record<string, SessionTemplate>) {
      this._sessionTemplates = value;
   }

   get sessionTemplatesList(): SessionTemplate[] {
      return Object.values(this._sessionTemplates).sort(
         (t1: SessionTemplate, t2: SessionTemplate) => {
            return (t2.updatedAt || 0) - (t1.updatedAt || 0);
         }
      );
   }

   initStore = () => {
      sessionTemplatesAPI.listenAll(this);
   };

   saveSessionTemplate = (id: string, updates: UpdateData<SessionTemplate>) => {
      return sessionTemplatesAPI.saveSessionTemplate(id, updates);
   };

   updateTemplate = (id: string) => {
      return sessionTemplatesAPI.updateTemplate(id);
   };
}

export { SessionTemplatesStore };
