import React, { FC } from 'react';
import { Grid, MenuItem, Select } from '@mui/material';
import {
   TierRestriction,
   TierRestrictionKeys,
   TierRestrictions,
} from '../../../../consts/types/tierRestrictions';
import css from './TierRestrictionSelection.module.scss';

interface Props {
   tierRestriction?: TierRestriction;
   onChange: (restriction: TierRestriction) => void;
}

const FcTierRestrictionsSelection: FC<Props> = (props) => {
   const { tierRestriction, onChange } = props;

   const restriction: TierRestriction = tierRestriction ?? 'Free';

   return (
      <Grid container alignItems="center" gap={2} className={css.root}>
         <label>Tier Restriction:</label>
         <Select
            className={css.select}
            value={restriction}
            onChange={(event) => onChange(event.target.value as TierRestriction)}
         >
            {TierRestrictionKeys.map((restrictionType) => {
               return (
                  <MenuItem key={restrictionType} value={restrictionType}>
                     {TierRestrictions[restrictionType]}
                  </MenuItem>
               );
            })}
         </Select>
      </Grid>
   );
};

export { FcTierRestrictionsSelection };
