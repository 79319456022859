import { makeAutoObservable, runInAction } from 'mobx';
import { SceneTemplate, SessionTemplate } from '../../consts/types/templates';
import { sceneTemplatesAPI } from '../../APIs/firebase/sceneTemplatesAPI';
import {
   SceneTemplateCategory,
   TemplatesByCategories,
   UncategorizedTemplatesCategory,
} from '../../consts/types/categories';
import { sceneTemplateCategoriesAPI } from '../../APIs/firebase/sceneTemplateCategoriesAPI';
import { addToArray, reorderArr } from '../../utils/array';
import { UpdateData } from '../../APIs/firebase';

class SceneTemplatesStore {
   private _sceneTemplatesList: SceneTemplate[] = [];
   private _sceneTemplates: Record<string, SceneTemplate> = {};
   private _sceneTemplateCategories: SceneTemplateCategory[] = [];

   constructor() {
      makeAutoObservable(this, {}, { autoBind: true });
   }

   get sceneTemplates(): Record<string, SceneTemplate> {
      return this._sceneTemplates;
   }

   get sceneTemplateCategories(): SceneTemplateCategory[] {
      return this._sceneTemplateCategories;
   }

   get sceneTemplatesList(): SceneTemplate[] {
      return this._sceneTemplatesList.filter((template) => !template.typeId);
   }

   get aiSceneTemplatesList(): SceneTemplate[] {
      return this._sceneTemplatesList.filter((template) => !!template.typeId)
         .sort((t1: SceneTemplate, t2: SceneTemplate) => {
            if (t1.aiEnabled !== t2.aiEnabled) {
               return t1.aiEnabled ? -1 : 1;
            }
            return (t2.updatedAt || 0) - (t1.updatedAt || 0);
         }
      );
   }

   get uncategorizedSceneTemplates(): SceneTemplate[] {
      const categorizedTemplateIds = new Set<string>();
      this._sceneTemplateCategories.forEach((category) => {
         category.templatesIds.forEach((id) => categorizedTemplateIds.add(id));
      });
      return this.sceneTemplatesList.filter((template) => !categorizedTemplateIds.has(template.id));
   }

   get sceneTemplatesByCategories(): TemplatesByCategories {
      const result: TemplatesByCategories = [];
      this._sceneTemplateCategories.forEach((category) => {
         const templates: SceneTemplate[] = [];
         category.templatesIds.forEach((id) => {
            const template = this.sceneTemplates[id];
            if (!template) return;
            templates.push(template);
         });
         result.push({ category, templates });
      });
      return result;
   }

   get tags(): string[] {
      const tagsSet: Set<string> = new Set<string>();
      this.sceneTemplatesList.forEach((sceneTemplate) => {
         sceneTemplate.tags.forEach((tag) => tagsSet.add(tag));
      });
      return Array.from(tagsSet);
   }

   updateSceneTemplateContent = async (id: string): Promise<void> => {
      return sceneTemplatesAPI.updateSceneTemplateContent(id);
   };

   saveSceneTemplate = (id: string, updates: UpdateData<SessionTemplate>) => {
      return sceneTemplatesAPI.saveSceneTemplate(id, updates);
   };

   addSceneTemplateCategory = (category: Partial<SceneTemplateCategory>) => {
      if (!category.name) return;
      return sceneTemplateCategoriesAPI.addSceneTemplateCategory(category.name);
   };

   updateSceneTemplateCategory = (id: string, updates: Partial<SceneTemplateCategory>) => {
      return sceneTemplateCategoriesAPI.updateSceneTemplateCategory(id, updates);
   };

   deleteSceneTemplateCategory = (categoryId: string) => {
      const category = this.sceneTemplateCategories.find((c) => c.id === categoryId);
      if (!category) {
         return alert('Category was not found');
      }
      if (category.templatesIds.length > 0) {
         return alert('Cannot delete category that is not empty. (have templates)');
      }
      return sceneTemplateCategoriesAPI.deleteSceneTemplateCategory(category.id);
   };

   addSceneTemplateToCategory = async (category: SceneTemplateCategory, templateId: string) => {
      if (category.templatesIds.includes(templateId)) {
         return alert('This scene template already exist in this scene template category');
      }
      const templatesIds = [templateId, ...category.templatesIds];
      await sceneTemplateCategoriesAPI.updateSceneTemplateCategory(category.id, { templatesIds });
   };

   deleteSceneTemplateFromCategory = (category: SceneTemplateCategory, templateId: string) => {
      const templatesIds = category.templatesIds.filter((id) => id !== templateId);
      return sceneTemplateCategoriesAPI.updateSceneTemplateCategory(category.id, { templatesIds });
   };

   reorderTemplatesInCategory = (categoryId: string, indexFrom: number, indexTo: number) => {
      const category = this.findSceneTemplateCategory(categoryId);
      if (!category) return;
      const templatesIds = reorderArr<string>(category.templatesIds, indexFrom, indexTo);
      return sceneTemplateCategoriesAPI.updateSceneTemplateCategory(category.id, { templatesIds });
   };

   moveTemplateToCategory = (
      templateId: string,
      sourceCategoryId: string,
      targetCategoryId: string,
      targetIndex: number
   ) => {
      const promises: Promise<void>[] = [];

      if (sourceCategoryId !== UncategorizedTemplatesCategory.id) {
         const sourceCategory = this.findSceneTemplateCategory(sourceCategoryId);
         if (!sourceCategory) {
            return alert('Source category was not found: ' + sourceCategoryId);
         }
         const sourceTemplatesIds = sourceCategory.templatesIds.filter((id) => id !== templateId);
         promises.push(
            sceneTemplateCategoriesAPI.updateSceneTemplateCategory(sourceCategoryId, {
               templatesIds: sourceTemplatesIds,
            })
         );
      }

      if (targetCategoryId !== UncategorizedTemplatesCategory.id) {
         const targetCategory = this.findSceneTemplateCategory(targetCategoryId);
         if (!targetCategory) {
            return alert('Target category was not found: ' + sourceCategoryId);
         }
         const targetTemplatesIds = addToArray(
            targetCategory.templatesIds,
            targetIndex,
            templateId
         );
         promises.push(
            sceneTemplateCategoriesAPI.updateSceneTemplateCategory(targetCategoryId, {
               templatesIds: targetTemplatesIds,
            })
         );
      }
      return Promise.all(promises);
   };

   private findSceneTemplateCategory = (categoryId: string): SceneTemplateCategory | undefined => {
      return this._sceneTemplateCategories.find((category) => category.id === categoryId);
   };

   initStore = () => {
      sceneTemplatesAPI.listenAll((sceneTemplates: SceneTemplate[]) => {
         const sceneTemplatesMap: Record<string, SceneTemplate> = {};
         sceneTemplates.forEach((sceneTemplate) => {
            sceneTemplatesMap[sceneTemplate.id] = sceneTemplate;
         });
         sceneTemplates.sort((t1: SceneTemplate, t2: SceneTemplate) => {
            return (t2.updatedAt || 0) - (t1.updatedAt || 0);
         });
         runInAction(() => {
            this._sceneTemplates = sceneTemplatesMap;
            this._sceneTemplatesList = sceneTemplates;
         });
      });

      sceneTemplateCategoriesAPI.listenAll((sceneTemplateCategories: SceneTemplateCategory[]) => {
         runInAction(() => {
            this._sceneTemplateCategories = sceneTemplateCategories;
         });
      });
   };
}

export { SceneTemplatesStore };
