import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { UserContext } from '../../../providers/user/UserProvider';
import { WrapperComp } from '../../../consts/types/general';
import FcLoginDialog from '../LoginDialog/LoginDialog';
import { getAuthInstance } from '../../../config/firebase';
import FcLoadingScreen from '../LoadingScreen/LoadingScreen';

const FCPrivateContent: WrapperComp = (props) => {
   const { children } = props;
   const { isUserAdmin, isLoading } = useContext(UserContext);
   const isLoadingUser = useAuthState(getAuthInstance())[1];

   if (isLoading || isLoadingUser) {
      return <FcLoadingScreen />;
   }
   if (isUserAdmin) {
      return <>{children}</>;
   }
   return <FcLoginDialog />;
};

export default observer(FCPrivateContent);
