import React, { FC } from 'react';
import { Dialog, Grid, Button } from '@mui/material';
import { userAPI } from '../../../APIs/firebase/userAPI';
import css from './LoginDialog.module.scss';

const FcLoginDialog: FC = () => {
   return (
      <Dialog open={true}>
         <div className={css.root}>
            <p className={css.title}>Welcome to Gloww CMS</p>
            <p className={css.text}>
               Please log-in <b>FROM ADMIN ACCOUNT</b> to continue. <br />
               only admin accounts can logged in
            </p>
            <Grid container justifyContent="center">
               <Button onClick={userAPI.signInWithPopup} className={css.btn}>
                  LOGIN WITH GOOGLE
               </Button>
            </Grid>
         </div>
      </Dialog>
   );
};

export default FcLoginDialog;
