import { doc, updateDoc, onSnapshot, getDocs, UpdateData } from '@firebase/firestore';
import { SessionTemplatesStore } from '../../providers/sessionTemplates/SessionTemplatesStore';
import { fbConvertor } from '../fbConvertor';
import { SessionTemplate } from '../../consts/types/templates';
import { createBiLogger } from '../BI';
import { ApiResourceNames, HttpMethods } from '../../consts/enums/general';
import { serverAPI } from './serverApi';
import { db } from './index';

const bi = createBiLogger('session-templates-API');

const sessionTemplatesAPI = {
   listenAll(store: SessionTemplatesStore) {
      const countLogger = bi.createCountLogger('all-session-templates');
      onSnapshot(
         db.sessionTemplates,
         (snapshot) => {
            countLogger.triggered();
            const sessionTemplates: Record<string, SessionTemplate> = {};
            snapshot.docs.forEach((item) => {
               const data = item.data();
               sessionTemplates[item.id] = fbConvertor.sessionTemplate(item.id, data);
            });
            store.sessionTemplates = sessionTemplates;
         },
         countLogger.error
      );
   },

   async saveSessionTemplate(id: string, updates: UpdateData<SessionTemplate>) {
      const actionLogger = bi.createActionLogger('save-session-template');
      if (updates.isTemplateInPool === undefined) {
         delete updates.isTemplateInPool;
      }
      if (updates.session === undefined) {
         delete updates.session;
      }
      try {
         const document = doc(db.sessionTemplates, id);
         await updateDoc(document, updates);
         actionLogger.success();
      } catch (err) {
         actionLogger.failed(err);
         throw err;
      }
   },

   async getThemesNames(): Promise<Map<string, string>> {
      const systemThemes = await getDocs(db.systemThemes);
      const themesNames: Map<string, string> = new Map<string, string>();
      systemThemes.forEach((theme) => {
         themesNames.set(theme.id, theme.get('name'));
      });
      themesNames.set('no-theme-id', 'No theme'); // option to add general preview video to template

      return themesNames;
   },

   async updateTemplate(templateId: string) {
      const actionLogger = bi.createActionLogger('update-template');
      try {
         await serverAPI.call(`${ApiResourceNames.TEMPLATES}/${templateId}`, HttpMethods.PATCH);
         actionLogger.success();
      } catch (err) {
         actionLogger.failed(err);
         throw err;
      }
   },
};

export { sessionTemplatesAPI };
