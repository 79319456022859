import React, { createContext, useEffect, useMemo } from 'react';
import { WrapperComp } from '../../consts/types/general';
import { SessionTemplateDataStore } from './SessionTemplateDataStore';
// @ts-ignore
const SessionTemplateDataContext = createContext<SessionTemplateDataStore>();

const SessionTemplateDataProvider: WrapperComp = (props) => {
   const { children } = props;
   const store = useMemo(() => new SessionTemplateDataStore(), []);

   useEffect(() => {
      store.initStore();
   }, []);

   return (
      <SessionTemplateDataContext.Provider value={store}>
         {children}
      </SessionTemplateDataContext.Provider>
   );
};

export { SessionTemplateDataContext, SessionTemplateDataProvider };
