import { onSnapshot, addDoc, doc, updateDoc, deleteDoc } from '@firebase/firestore';
import orderBy from 'lodash/orderBy';
import { fbConvertor } from '../fbConvertor';
import { createBiLogger } from '../BI';
import { FbSessionTemplatesCategory, SessionTemplateCategory } from '../../consts/types/categories';
import { SessionTemplateCategoriesStore } from '../../providers/sessionTemplateCategories/SessionTemplateCategoriesStore';
import { db } from './index';

const bi = createBiLogger('session-templates-categories-API');

const sessionTemplateCategoriesAPI = {
   listenAll(store: SessionTemplateCategoriesStore) {
      const countLogger = bi.createCountLogger('all-session-categories');
      onSnapshot(
         db.templatesCategoriesWIP,
         (snapshot) => {
            countLogger.triggered();
            const categories = snapshot.docs.map((item) => {
               const data = item.data();
               return { id: item.id, data };
            });
            const orderCategories = orderBy(categories, (item) => item.data.order);
            store.sessionTemplateCategories = orderCategories.map((item) => {
               return fbConvertor.templateCategory(item.id, item.data);
            });
         },
         countLogger.error
      );
   },

   async add(
      name: string,
      isSuggested: boolean | undefined,
      suggestedTitle: string | undefined,
      order: number
   ) {
      const actionLogger = bi.createActionLogger('create-session-template-category');
      try {
         const category: FbSessionTemplatesCategory = {
            name,
            order,
            templatesIds: [],
         };
         if (isSuggested !== undefined) {
            category.isSuggested = isSuggested;
         }
         if (suggestedTitle !== undefined) {
            category.suggestedTitle = suggestedTitle;
         }
         await addDoc(db.templatesCategoriesWIP, category);
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },

   async update(id: string, updates: Partial<SessionTemplateCategory>) {
      const actionLogger = bi.createActionLogger('update-session-template-category');
      try {
         const document = doc(db.templatesCategoriesWIP, id);
         await updateDoc(document, updates);
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },

   async delete(id: string) {
      const actionLogger = bi.createActionLogger('delete-session-template-category');
      try {
         const document = doc(db.templatesCategoriesWIP, id);
         await deleteDoc(document);
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },
};

export { sessionTemplateCategoriesAPI };
