import React, { FC, memo } from 'react';
import css from './MediaPreview.module.scss';

interface Props {
   imgURL: string;
   videoURL?: string;
}

const FcMediaPreview: FC<Props> = (props) => {
   const { imgURL, videoURL } = props;
   return (
      <div className={css.previewContainer}>
         <p>Img preview</p>
         <div className={css.preview}>
            <div>No img to preview</div>
            <img alt="" src={imgURL} />
         </div>
         <p>Video preview</p>
         <div className={css.preview}>
            <div>No video to preview</div>
            <video muted autoPlay loop src={videoURL} />
         </div>
      </div>
   );
};

export default memo(FcMediaPreview);
