import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Template } from '../../../../../consts/types/templates';
import { DraggableIdSeparator } from '../../../../../consts/types/general';
import { TemplateCategory } from '../../../../../consts/types/categories';
import { FcTemplatePreview } from '../../../TemplatePreview/TemplatePreview';
import css from './DraggableTemplate.module.scss';

interface Props {
   template: Template;
   category: TemplateCategory;
   index: number;
   onClick: () => void;
}

const FcDraggableTemplate: FC<Props> = (props) => {
   const { template, category, index, onClick } = props;
   return (
      <Draggable draggableId={`${category.id}${DraggableIdSeparator}${template.id}`} index={index}>
         {(provided) => (
            <div
               ref={provided.innerRef}
               {...provided.draggableProps}
               {...provided.dragHandleProps}
               style={provided.draggableProps.style}
               className={css.container}
            >
               <FcTemplatePreview template={template} onClick={onClick} />
            </div>
         )}
      </Draggable>
   );
};

export default FcDraggableTemplate;
