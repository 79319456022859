import React, { FC, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { DraggableIdSeparator } from '../../../consts/types/general';
import { Template, TemplateType } from '../../../consts/types/templates';
import {
   TemplateCategory,
   TemplatesByCategories,
   UncategorizedTemplatesCategory,
} from '../../../consts/types/categories';
import FcTemplateDialog from '../dialogs/TemplateDialog/TemplateDialog';
import FcTemplatesOfCategory from './TemplatesOfCategory/TemplatesOfCategory';
import FcDraggableTemplate from './TemplatesOfCategory/DraggbleTemplate/DraggableTemplate';
import css from './CategoriesWithTemplates.module.scss';

interface Props {
   templatesByCategories: TemplatesByCategories;
   uncategorizedTemplates?: Template[];
   templateType: TemplateType;
   allTemplates?: Template[];
   addTemplateToCategory: (category: TemplateCategory, templateId: string) => void;
   moveTemplateToCategory: (
      templateId: string,
      sourceCategoryId: string,
      targetCategoryId: string,
      targetIndex: number
   ) => void;
   deleteTemplateFromCategory: (category: TemplateCategory, templateId: string) => void;
   reorderTemplatesInCategory: (
      targetCategoryId: string,
      indexFrom: number,
      indexTo: number
   ) => void;
}

const FcCategoriesWithTemplates: FC<Props> = (props) => {
   const {
      templatesByCategories,
      uncategorizedTemplates,
      templateType,
      allTemplates,
      addTemplateToCategory,
      moveTemplateToCategory,
      deleteTemplateFromCategory,
      reorderTemplatesInCategory,
   } = props;

   const [currentTemplate, setCurrentTemplate] = useState<Template | undefined>();

   const handleDragEnd = (result: DropResult) => {
      if (!result.destination) return;
      const ids = result.draggableId.split(DraggableIdSeparator);
      const sourceCategoryId = ids[0];
      const templateId = ids[1];
      const targetCategoryId = result.destination.droppableId;
      const indexFrom = result.source.index;
      const indexTo = result.destination.index;
      if (targetCategoryId === sourceCategoryId) {
         return reorderTemplatesInCategory(targetCategoryId, indexFrom, indexTo);
      }
      moveTemplateToCategory(templateId, sourceCategoryId, targetCategoryId, indexTo);
   };

   return (
      <DragDropContext onDragEnd={handleDragEnd}>
         <div className={css.templatesByCategories}>
            {templatesByCategories.map((item) => (
               <FcTemplatesOfCategory
                  key={item.category.id}
                  category={item.category}
                  categoryTemplates={item.templates}
                  allTemplates={allTemplates}
                  templateType={templateType}
                  onItemClick={(id) => addTemplateToCategory(item.category, id)}
                  onDeleteItem={(id) => deleteTemplateFromCategory(item.category, id)}
               />
            ))}
         </div>

         {uncategorizedTemplates && (
            <>
               <h1>Uncategorized Templates</h1>
               <Droppable droppableId={UncategorizedTemplatesCategory.id} direction="horizontal">
                  {(provided) => (
                     <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        tabIndex={0}
                        className={css.uncategorized}
                     >
                        {uncategorizedTemplates.map((sceneTemplate, index) => (
                           <FcDraggableTemplate
                              key={sceneTemplate.id}
                              template={sceneTemplate}
                              category={UncategorizedTemplatesCategory}
                              index={index}
                              onClick={() => setCurrentTemplate(sceneTemplate)}
                           />
                        ))}
                        {provided.placeholder}
                     </div>
                  )}
               </Droppable>
               {currentTemplate && (
                  <FcTemplateDialog
                     template={currentTemplate}
                     templateType={templateType}
                     open
                     toggleOpen={() => setCurrentTemplate(undefined)}
                  />
               )}
            </>
         )}
      </DragDropContext>
   );
};

export { FcCategoriesWithTemplates };
