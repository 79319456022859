import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Grid, Input, Switch } from '@mui/material';
import { useFormik } from 'formik';
import {
   CategoryType,
   SessionTemplateCategory,
   TemplateCategory,
} from '../../../../consts/types/categories';
import FcFormButtons from '../FormButtons/FormButtons';
import FcDialogTitle from '../DialogTitle/DialogTitle';
import css from './CategoryDialog.module.scss';

interface Props {
   type: CategoryType;
   category: TemplateCategory;
   open: boolean;
   toggleOpen: () => void;
   updateCategory: (categoryId: string, updates: Partial<TemplateCategory>) => void;
   deleteCategory: (categoryId: string) => void;
}

const getInitialValues = (type: CategoryType, category: TemplateCategory) => {
   if (type === 'sessionTemplate') {
      const sessionTemplateCategory = category as SessionTemplateCategory;
      return {
         name: sessionTemplateCategory.name,
         isSuggested: sessionTemplateCategory.isSuggested,
         suggestedTitle: sessionTemplateCategory.suggestedTitle,
      };
   }

   return {
      name: category.name,
      isSuggested: false,
      suggestedTitle: '',
   };
};

const FcCategoryDialog: FC<Props> = (props) => {
   const { type, category, open, toggleOpen, updateCategory, deleteCategory } = props;

   const isEdit = !!category.id;

   const initialValues = getInitialValues(type, category);
   const formik = useFormik({
      initialValues,
      onSubmit: async (values) => {
         await updateCategory(category.id, values);
         toggleOpen();
      },
   });

   return (
      <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={toggleOpen}>
         <FcDialogTitle text={isEdit ? 'Edit Category' : 'Add Category'} onClose={toggleOpen} />

         <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} autoComplete="off">
            <div className={css.form}>
               <Grid container alignItems="center" gap="8px">
                  <label>Name:</label>
                  <Input
                     autoFocus
                     className={css.input}
                     id="name"
                     value={formik.values.name}
                     required
                     onChange={formik.handleChange}
                  />
                  {type === 'sessionTemplate' && (
                     <>
                        <Grid container alignItems="center" gap={2}>
                           <label>Suggested in Homepage:</label>
                           <Switch
                              itemID="suggested"
                              checked={formik.values.isSuggested}
                              onChange={(_, value) => formik.setFieldValue('isSuggested', value)}
                           />
                        </Grid>
                        {formik.values.isSuggested && (
                           <Grid container alignItems="center" gap={2}>
                              <label>Suggested Title:</label>
                              <Input
                                 autoFocus
                                 className={css.input}
                                 id="suggestedTitle"
                                 value={formik.values.suggestedTitle}
                                 required={formik.values.isSuggested}
                                 onChange={formik.handleChange}
                              />
                           </Grid>
                        )}
                     </>
                  )}
               </Grid>

               {isEdit && (
                  <Grid container alignItems="center" gap="8px">
                     <label>Category ID:</label>
                     <span className={css.input}>{category.id}</span>
                  </Grid>
               )}

               {isEdit && (
                  <Grid container alignItems="center" gap="8px">
                     <label>Templates Amount:</label>
                     <span className={css.input}>{category.templatesIds.length}</span>
                  </Grid>
               )}
            </div>

            <FcFormButtons onDelete={isEdit ? () => deleteCategory(category.id) : undefined} />
         </form>
      </Dialog>
   );
};

export default observer(FcCategoryDialog);
