import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Grid, Portal, Snackbar } from '@mui/material';
import { Template } from '../../../../consts/types/templates';
import { TemplateCategory } from '../../../../consts/types/categories';
import { FcTemplatePreview } from '../../TemplatePreview/TemplatePreview';
import FcDialogTitle from '../DialogTitle/DialogTitle';
import css from './SelectTemplatesDialog.module.scss';

interface Props {
   category: TemplateCategory;
   allTemplates: Template[];
   open: boolean;
   selectedTemplatesIds: string[];
   toggleOpen: () => void;
   onItemClick?: (id: string) => void;
   onDeleteItem?: (id: string) => void;
}

const initialSnackBar = {
   id: 'snack',
   open: false,
   message: '',
};

const FcSelectTemplateDialog: FC<Props> = (props) => {
   const {
      open,
      toggleOpen,
      category,
      allTemplates,
      onItemClick,
      onDeleteItem,
      selectedTemplatesIds,
   } = props;
   const [snackbar, setSnackBar] = useState(initialSnackBar);

   return (
      <>
         <Dialog open={open} fullWidth maxWidth="xl" onClose={toggleOpen}>
            <FcDialogTitle
               text={`Add templates to category: ${category.name}`}
               onClose={toggleOpen}
            />
            <Grid container className={css.list} gap="16px">
               {allTemplates.map((item) => (
                  <FcTemplatePreview
                     key={item.id}
                     template={item}
                     onClick={() => {
                        if (selectedTemplatesIds.includes(item.id)) {
                           onDeleteItem?.(item.id);
                           setSnackBar((prev) => ({
                              ...prev,
                              id: `${item.id}-removed`,
                              open: true,
                              message: `Template ${item.name} removed from ${category.name}`,
                           }));
                        } else {
                           onItemClick?.(item.id);
                           setSnackBar((prev) => ({
                              ...prev,
                              id: `${item.id}-added`,
                              open: true,
                              message: `Template ${item.name} added to ${category.name}`,
                           }));
                        }
                     }}
                     isSelected={selectedTemplatesIds.includes(item.id)}
                     isSelectable
                  />
               ))}
            </Grid>
         </Dialog>
         <Portal container={document.body}>
            <Snackbar
               open={snackbar.open}
               autoHideDuration={2000}
               onClose={() => setSnackBar((prev) => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
               key={snackbar.id}
               message={snackbar.message}
               sx={{ zIndex: 2000 }}
            />
         </Portal>
      </>
   );
};

export default observer(FcSelectTemplateDialog);
