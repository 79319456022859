import React, { FC, ReactNode } from 'react';
import { Dialog } from '@mui/material';
import { useToggle } from '../../../../hooks/useToggle';
import FcDialogTitle from '../DialogTitle/DialogTitle';
import css from './AlertDialog.module.scss';

interface Props {
   title: string;
   description: string | ReactNode;
   confirmText: string;
   cancelText?: string;
   onConfirm: () => void;
   onCancel?: () => void;
}

const FcAlertDialog: FC<Props> = (props) => {
   const { title, description, confirmText, cancelText, onConfirm, onCancel } = props;
   const [open, toggleOpen] = useToggle(true);

   return (
      <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={toggleOpen}>
         <FcDialogTitle text={title} onClose={toggleOpen} />
         <div className={css.description}>{description}</div>
         <div className={css.buttons}>
            <button onClick={onConfirm}>{confirmText}</button>
            {cancelText && onCancel && <button onClick={onCancel}>{cancelText}</button>}
         </div>
      </Dialog>
   );
};

export { FcAlertDialog };
