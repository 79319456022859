import React, { FC, memo } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import css from './LoadingScreen.module.scss';

const FcLoadingScreen: FC = () => {
   return (
      <Grid container alignItems="center" justifyContent="center" className={css.loadingScreen}>
         <CircularProgress size={60} />
      </Grid>
   );
};

export default memo(FcLoadingScreen);
