import { FbSession, Session } from '../consts/types/sessions';
import {
   AudienceSize,
   FbSessionTemplate,
   SessionTemplate,
   FbSceneTemplate,
   SceneTemplate,
} from '../consts/types/templates';
import {
   FbSessionTemplatesCategory,
   SessionTemplateCategory,
   FbSceneTemplatesCategory,
   SceneTemplateCategory,
} from '../consts/types/categories';

export const defaultAudienceSize: AudienceSize = {
   from: 10,
   to: 40,
};

const fbConvertor = {
   sessionTemplate(id: string, fbTemplate: FbSessionTemplate): SessionTemplate {
      return {
         id,
         session: fbTemplate.session,
         sessionId: fbTemplate.sessionId,
         name: fbTemplate.name ?? '',
         description: fbTemplate.description ?? '',
         createdAt: fbTemplate.createdAt ?? 0,
         updatedAt: fbTemplate.updatedAt ?? 0,
         imgURL: fbTemplate.imgURL ?? '',
         videoURL: fbTemplate.videoURL ?? '',
         isVisible: fbTemplate.isVisible ?? false,
         isThemeable: fbTemplate.isThemeable || false,
         isTemplateInPool: fbTemplate.isTemplateInPool,
         supportedThemes: fbTemplate.supportedThemes,
         tags: fbTemplate.tags,
         badgeId: fbTemplate.badgeId ?? '',
         audienceSize: fbTemplate.audienceSize,
         relatedTemplatesIds: fbTemplate.relatedTemplatesIds ?? [],
         tierRestriction: fbTemplate.tierRestriction,
      };
   },

   templateCategory(id: string, fbTag: FbSessionTemplatesCategory): SessionTemplateCategory {
      return {
         id,
         name: fbTag.name ?? '',
         templatesIds: fbTag.templatesIds ?? [],
         isSuggested: fbTag.isSuggested,
         suggestedTitle: fbTag.suggestedTitle,
      };
   },

   session(id: string, fbSession: FbSession): Session {
      return {
         id,
         templateId: fbSession.templateId || '',
         createdAt: fbSession.createdAt,
         updatedAt: fbSession.updatedAt || fbSession.createdAt,
      };
   },

   sceneTemplate(id: string, fbSceneTemplate: FbSceneTemplate): SceneTemplate {
      return {
         id,
         name: fbSceneTemplate.name ?? '',
         description: fbSceneTemplate.description ?? '',
         sessionId: fbSceneTemplate.sessionId,
         scenesIds: fbSceneTemplate.scenesIds || [],
         tags: fbSceneTemplate.tags || [],
         previewUrl: fbSceneTemplate.previewUrl || '',
         videoUrl: fbSceneTemplate.videoUrl || '',
         isVisible: fbSceneTemplate.isVisible || false,
         tierRestriction: fbSceneTemplate.tierRestriction,
         createdAt: fbSceneTemplate.createdAt,
         updatedBy: fbSceneTemplate.updatedBy,
         updatedAt: fbSceneTemplate.updatedAt,
         genre: fbSceneTemplate.genre,
         typeId: fbSceneTemplate.typeId,
         aiEnabled: fbSceneTemplate.aiEnabled,
      };
   },

   sceneTemplateCategory(
      id: string,
      fbSceneTemplatesCategory: FbSceneTemplatesCategory
   ): SceneTemplateCategory {
      return {
         id,
         name: fbSceneTemplatesCategory.name ?? '',
         templatesIds: fbSceneTemplatesCategory.templatesIds ?? [],
      };
   },
};

export { fbConvertor };
