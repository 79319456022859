import React, { FC, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Template, TemplateType } from '../../../../consts/types/templates';
import { TemplateCategory } from '../../../../consts/types/categories';
import { useToggle } from '../../../../hooks/useToggle';
import FcSelectTemplateDialog from '../../dialogs/SelectTemplatesDialog/SelectTemplatesDialog';
import FcTemplateDialog from '../../../UI/dialogs/TemplateDialog/TemplateDialog';
import FcDraggableTemplate from './DraggbleTemplate/DraggableTemplate';
import css from './TemplatesOfCategory.module.scss';

interface Props {
   category: TemplateCategory;
   categoryTemplates: Template[];
   allTemplates?: Template[];
   templateType: TemplateType;
   onItemClick: (id: string) => void;
   onDeleteItem: (id: string) => void;
}

const FcTemplatesOfCategory: FC<Props> = (props) => {
   const { category, categoryTemplates, allTemplates, templateType, onItemClick, onDeleteItem } =
      props;
   const [selectTemplateDialogOpen, toggleSelectTemplateDialogOpen] = useToggle();
   const [curTemplate, setCurTemplate] = useState<Template | null>(null);

   return (
      <>
         {allTemplates && (
            <FcSelectTemplateDialog
               open={selectTemplateDialogOpen}
               toggleOpen={toggleSelectTemplateDialogOpen}
               category={category}
               allTemplates={allTemplates}
               onItemClick={(id) => onItemClick(id)}
               onDeleteItem={onDeleteItem}
               selectedTemplatesIds={categoryTemplates.map((item) => item?.id)}
            />
         )}
         {curTemplate && (
            <FcTemplateDialog
               open
               toggleOpen={() => setCurTemplate(null)}
               onDeleteItem={onDeleteItem}
               templateType={templateType}
               template={curTemplate}
               category={category}
            />
         )}
         <div className={css.header}>
            <p className={css.label}>{category.name}</p>
            <button
               className={css.add}
               onClick={(e) => {
                  e.preventDefault();
                  toggleSelectTemplateDialogOpen();
               }}
            >
               +
            </button>
         </div>
         <Droppable droppableId={category.id} direction="horizontal">
            {(provided) => (
               <div
                  className={css.list}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  tabIndex={0}
               >
                  {categoryTemplates.map((template, i) => (
                     <FcDraggableTemplate
                        key={template.id}
                        template={template}
                        category={category}
                        index={i}
                        onClick={() => setCurTemplate(template)}
                     />
                  ))}
                  {provided.placeholder}
               </div>
            )}
         </Droppable>
      </>
   );
};

export default FcTemplatesOfCategory;
