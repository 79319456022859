import { Template } from './templates';

export type CategoryType = 'sessionTemplate' | 'sceneTemplate';

export interface TemplateCategory {
   id: string;
   name: string;
   templatesIds: string[];
}

export type FbSessionTemplatesCategory = {
   name: string;
   templatesIds: string[];
   order: number;
   isSuggested?: boolean;
   suggestedTitle?: string;
};

export interface SessionTemplateCategory extends TemplateCategory {
   isSuggested?: boolean;
   suggestedTitle?: string;
}

export type FbSceneTemplatesCategory = {
   name: string;
   templatesIds: string[];
};

export interface SceneTemplateCategory extends TemplateCategory {}

export type TemplatesByCategories = {
   category: TemplateCategory;
   templates: Template[];
}[];

export const UncategorizedTemplatesCategory: TemplateCategory = {
   id: 'uncategorized-templates',
   name: 'Uncategorized Templates',
   templatesIds: [],
};
