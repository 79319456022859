import { ApiResourceNames, HttpMethods } from '../../consts/enums/general';
import { createBiLogger } from '../BI';
import { serverAPI } from './serverApi';

const bi = createBiLogger('publish-API');

const publishAPI = {
   async publishChanges() {
      const actionLogger = bi.createActionLogger('publish changes');
      try {
         await serverAPI.call(`${ApiResourceNames.TEMPLATES}/categories/publish`, HttpMethods.POST);
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },

   async revertChanges() {
      const actionLogger = bi.createActionLogger('revert changes');
      try {
         await serverAPI.call(`${ApiResourceNames.TEMPLATES}/categories/revert`, HttpMethods.POST);
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },
};

export { publishAPI };
