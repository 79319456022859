import { doc, updateDoc, onSnapshot, UpdateData } from '@firebase/firestore';
import { fbConvertor } from '../fbConvertor';
import { SceneTemplate } from '../../consts/types/templates';
import { createBiLogger } from '../BI';
import { ApiResourceNames, HttpMethods } from '../../consts/enums/general';
import { serverAPI } from './serverApi';
import { db } from './index';
const bi = createBiLogger('scene-templates-API');

const sceneTemplatesAPI = {
   listenAll(onSceneTemplatesUpdates: (sceneTemplates: SceneTemplate[]) => void) {
      const countLogger = bi.createCountLogger('all-scene-templates');
      onSnapshot(
         db.sceneTemplates,
         (snapshot) => {
            countLogger.triggered();
            const sceneTemplates: SceneTemplate[] = [];
            snapshot.docs.forEach((item) => {
               const data = item.data();
               const sceneTemplate = fbConvertor.sceneTemplate(item.id, data);
               sceneTemplates.push(sceneTemplate);
            });
            onSceneTemplatesUpdates(sceneTemplates);
         },
         countLogger.error
      );
   },

   async saveSceneTemplate(id: string, updates: UpdateData<SceneTemplate>) {
      const actionLogger = bi.createActionLogger('save-scene-template');
      try {
         const document = doc(db.sceneTemplates, id);
         await updateDoc(document, updates);
         actionLogger.success();
      } catch (err) {
         actionLogger.failed(err);
         throw err;
      }
   },

   async updateSceneTemplateContent(id: string) {
      return serverAPI.call(
         `${ApiResourceNames.SCENE_TEMPLATES}/${id}/content`,
         HttpMethods.PUT
      );
   },
};

export { sceneTemplatesAPI };
