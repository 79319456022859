import React, { FC } from 'react';
import { Divider, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import css from './DialogTitle.module.scss';

interface Props {
   text: string;
   onClose: () => void;
}

const FcDialogTitle: FC<Props> = (props) => {
   const { text, onClose } = props;
   return (
      <>
         <Grid
            container
            alignItems="center"
            sx={{ padding: '0 16px' }}
            justifyContent="space-between"
         >
            <h2 className={css.title}>{text}</h2>
            <CloseIcon onClick={onClose} className={css.close} />
         </Grid>
         <Divider />
      </>
   );
};

export default FcDialogTitle;
