import React, { Link, NavLink, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import LogoutIcon from '@mui/icons-material/Logout';
import { Grid, IconButton } from '@mui/material';
import { Routes } from '../../../consts/enums/general';
import { UserContext } from '../../../providers/user/UserProvider';
import { userAPI } from '../../../APIs/firebase/userAPI';
import css from './Header.module.scss';

const FcHeader = () => {
   const { user } = useContext(UserContext);
   const location = useLocation();
   if (!user) return null;

   const isSelected = (link: string): boolean => {
      return location.pathname.substring(1) === link;
   };

   return (
      <nav className={css.header}>
         <ul>
            <li>
               <Link className={css.logo} to="/">
                  <img src="/logo.png" alt="icon" />
               </Link>
            </li>
            <li className={clsx(css.link, isSelected(Routes.SESSION_TEMPLATES) && css.selected)}>
               <NavLink to={Routes.SESSION_TEMPLATES}>Session Templates</NavLink>
            </li>
            <li className={clsx(css.link, isSelected(Routes.SCENE_TEMPLATES) && css.selected)}>
               <NavLink to={Routes.SCENE_TEMPLATES}>Scene Templates</NavLink>
            </li>
         </ul>
         <Grid container justifyContent="flex-end" alignItems="center">
            <img src={user.photoURL} className={css.userPreview} alt="user" />
            <p className={css.username}>{user.displayName}</p>
            <IconButton className={css.logOut} onClick={userAPI.signOut}>
               <LogoutIcon />
            </IconButton>
         </Grid>
      </nav>
   );
};

export default observer(FcHeader);
