export enum Routes {
   SESSION_TEMPLATES = 'sessionTemplates',
   SCENE_TEMPLATES = 'sceneTemplates',
   PUBLISH = 'publish',
}

export enum HttpMethods {
   GET = 'GET',
   POST = 'POST',
   PUT = 'PUT',
   DELETE = 'DELETE',
   PATCH = 'PATCH',
}

export enum ApiResourceNames {
   SESSIONS = 'sessions',
   TEMPLATES = 'templates',
   TEMPLATES_CATEGORIES = 'templateCategories',
   TEMPLATES_CATEGORIES_WIP = 'templateCategoriesWip',
   SCENE_TEMPLATES = 'sceneTemplates',
}
