import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { CategoryType, TemplateCategory } from '../../../consts/types/categories';
import FcCategoryDialog from '../dialogs/CategoryDialog/CategoryDialog';
import FcCategoryItem from './CategoryItem/CategoryItem';
import css from './CategoryManagement.module.scss';

interface Props {
   categories: TemplateCategory[];
   type: CategoryType;
   addCategory: (value: Partial<TemplateCategory>) => void;
   updateCategory: (categoryId: string, updates: Partial<TemplateCategory>) => void;
   deleteCategory: (categoryId: string) => void;
}

const FcCategoryManagement: FC<Props> = (props) => {
   const { type, categories, addCategory, updateCategory, deleteCategory } = props;
   const [selectedCategory, setSelectedCategory] = useState<TemplateCategory | undefined>();

   const toggleDialogOpen = () => {
      setSelectedCategory(undefined);
   };

   const openAddCategoryDialog = () => {
      setSelectedCategory({
         id: '',
         name: '',
         templatesIds: [],
      });
   };

   const onUpdateCategory = (categoryId: string, value: Partial<TemplateCategory>) => {
      if (selectedCategory?.id) {
         updateCategory(categoryId, value);
      } else {
         addCategory(value);
      }
   };

   const onDeleteCategory = (categoryId: string) => {
      deleteCategory(categoryId);
      toggleDialogOpen();
   };

   return (
      <>
         <Grid container alignItems="center" className={css.header}>
            <p className={css.label}>All categories</p>
            <button className={css.add} onClick={openAddCategoryDialog}>
               +
            </button>
         </Grid>
         <Grid container className={css.list}>
            {categories.map((category) => (
               <FcCategoryItem
                  key={category.id}
                  category={category}
                  onClick={() => setSelectedCategory(category)}
               />
            ))}
         </Grid>
         {selectedCategory && (
            <FcCategoryDialog
               type={type}
               category={selectedCategory}
               open={!!selectedCategory}
               toggleOpen={toggleDialogOpen}
               updateCategory={onUpdateCategory}
               deleteCategory={onDeleteCategory}
            />
         )}
      </>
   );
};

export { FcCategoryManagement };
