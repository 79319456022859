import React, { FC } from 'react';
import { TemplateCategory } from '../../../../consts/types/categories';
import css from './CategoryItem.module.scss';

interface Props {
   category: TemplateCategory;
   onClick: () => void;
}

const FcCategoryItem: FC<Props> = (props) => {
   const { category, onClick } = props;

   return (
      <>
         <p className={css.item} onClick={onClick}>
            {category.name}
         </p>
      </>
   );
};

export default FcCategoryItem;
