function reorderArr<T>(arr: T[], indexFrom: number, indexTo: number) {
   const newOrder = [...arr];
   const [removed] = newOrder.splice(indexFrom, 1);
   newOrder.splice(indexTo, 0, removed);
   return newOrder;
}

function addToArray<T>(arr: T[], indexTo: number, item: T) {
   const newOrder = [...arr];
   newOrder.splice(indexTo, 0, item);
   return newOrder;
}

export { reorderArr, addToArray };
