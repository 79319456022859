import { getDoc } from '@firebase/firestore';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { UserStore } from '../../providers/user/UserStore';
import { createBiLogger } from '../BI';
import { getAuthInstance } from '../../config/firebase';
import { db } from './index';

const fbAuthInstance = getAuthInstance();
const fbAuthProvider = new GoogleAuthProvider();
fbAuthProvider.addScope('email');
fbAuthProvider.addScope('profile');
fbAuthProvider.setCustomParameters({
   login_hint: 'user@example.com',
   // the next line will force the selection of the google account, even if there is only 1 logged-in account in the browser
   prompt: 'select_account',
});
const bi = createBiLogger('user-API');

const userAPI = {
   listenAuthChange(userStore: UserStore) {
      fbAuthInstance.onAuthStateChanged((result) => {
         if (!result) {
            bi.log('user logged out');
            userStore.user = null;
            return;
         }

         bi.log('user logged in');
         userStore.user = {
            uid: result.uid,
            displayName: result.displayName || '',
            email: result.email || '',
            photoURL: result.photoURL || '',
         };
      });
   },

   async getAdminsEmails(store: UserStore) {
      const biAction = bi.createActionLogger('get admins emails');
      try {
         const adminsEmails = await getDoc(db.adminsEmails);
         const data = adminsEmails.data();
         // Im not using fbConvertor and all that because this is only temporary
         store.adminsEmails = data?.emails || [];
         biAction.success();
      } catch (e) {
         biAction.failed(e);
      }
   },

   async signInWithPopup() {
      const biAction = bi.createActionLogger('sign in with popup');
      try {
         await signInWithPopup(fbAuthInstance, fbAuthProvider);
         biAction.success();
      } catch (e) {
         biAction.failed(e);
      }
   },

   async signOut() {
      const biAction = bi.createActionLogger('sign out');
      try {
         await fbAuthInstance.signOut();
         biAction.success();
      } catch (e) {
         biAction.failed(e);
      }
   },

   async getAuthToken() {
      const biAction = bi.createActionLogger('get auth token');
      try {
         const token = await fbAuthInstance.currentUser?.getIdToken();
         biAction.success();
         return token;
      } catch (e) {
         biAction.failed(e);
      }
   },
};

export { userAPI };
