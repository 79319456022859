import { makeAutoObservable } from 'mobx';
import { User } from '../../consts/types/user';
import { userAPI } from '../../APIs/firebase/userAPI';

class UserStore {
   private _isLoading = true;
   private _user: User | null = null;
   private _adminsEmails: string[] = [];
   constructor() {
      makeAutoObservable(this, {}, { autoBind: true });
   }

   get user() {
      return this._user;
   }
   set user(user: User | null) {
      this._user = user;
   }

   get isLoading() {
      return this._isLoading;
   }

   get isUserAdmin(): boolean {
      // return this._user?.role === UserRoles.ADMIN;
      if (!this._user) return false;
      return this._adminsEmails.includes(this._user.email);
   }

   set adminsEmails(emails: string[]) {
      this._adminsEmails = emails;
      this._isLoading = false;
   }

   initStore = () => {
      userAPI.listenAuthChange(this);
      userAPI.getAdminsEmails(this);
   };
}

export { UserStore };
