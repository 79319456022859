import React, { FC } from 'react';
import { Redirect, Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import FcHeader from '../UI/Header/Header';
import { Routes } from '../../consts/enums/general';
import { FcSessionTemplatesRoute } from '../routes/SessionTemplatesRoute/SessionTemplatesRoute';
import { FcSceneTemplatesRoute } from '../routes/SceneTemplatesRoute/SceneTemplatesRoute';
import { SessionTemplatesProvider } from '../../providers/sessionTemplates/SessionTemplatesProvider';
import { SessionTemplateDataProvider } from '../../providers/sessionTemplates/SessionTemplateDataProvider';
import { SessionTemplateCategoriesProvider } from '../../providers/sessionTemplateCategories/SessionTemplateCategoriesProvider';
import { UserProvider } from '../../providers/user/UserProvider';
import { SceneTemplatesProvider } from '../../providers/sceneTemplates/SceneTemplatesProvider';
import FCPrivateContent from '../UI/PrivateContent/PrivateContent';
import css from './App.module.scss';

const FcRoutes: FC = () => {
   return (
      <Switch>
         <Route path={'/' + Routes.SESSION_TEMPLATES}>
            <FcSessionTemplatesRoute />
         </Route>
         <Route path={'/' + Routes.SCENE_TEMPLATES}>
            <FcSceneTemplatesRoute />
         </Route>
         <Route path="/*" exact>
            <Redirect to={'/' + Routes.SESSION_TEMPLATES} />
         </Route>
      </Switch>
   );
};

const FcApp: FC = () => {
   return (
      <Router>
         <UserProvider>
            <SessionTemplatesProvider>
               <SessionTemplateDataProvider>
                  <SessionTemplateCategoriesProvider>
                     <SceneTemplatesProvider>
                        <FCPrivateContent>
                           <div className={css.appContainer}>
                              <FcHeader />
                              <div className={css.contentContainer}>
                                 <FcRoutes />
                              </div>
                           </div>
                        </FCPrivateContent>
                     </SceneTemplatesProvider>
                  </SessionTemplateCategoriesProvider>
               </SessionTemplateDataProvider>
            </SessionTemplatesProvider>
         </UserProvider>
      </Router>
   );
};

export default FcApp;
