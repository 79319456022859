import { updateDoc, onSnapshot } from '@firebase/firestore';
import { TemplateBadge } from '../../consts/types/templates';
import { createBiLogger } from '../BI';
import { db } from './index';
const bi = createBiLogger('template-data-API');

interface TemplateTagsConsumer {
   set tags(value: string[]);
}

interface TemplateBadgesConsumer {
   set badges(value: TemplateBadge[]);
}

const templateDataAPI = {
   listenBadges(consumer: TemplateBadgesConsumer) {
      const countLogger = bi.createCountLogger('templates-badges');
      onSnapshot(
         db.templateBadges,
         (snapshot) => {
            countLogger.triggered();
            const templateBadges = snapshot.data();
            consumer.badges = templateBadges?.badges || ([] as TemplateBadge[]);
         },
         (error) => {
            countLogger.error(error);
         }
      );
   },

   listenTags(consumer: TemplateTagsConsumer) {
      const countLogger = bi.createCountLogger('templates-tags');
      onSnapshot(
         db.templateTags,
         (snapshot) => {
            countLogger.triggered();
            const templateTags = snapshot.data();
            consumer.tags = templateTags?.tags || ([] as string[]);
         },
         (error) => {
            countLogger.error(error);
         }
      );
   },

   async updateTags(tags: string[]) {
      const actionLogger = bi.createActionLogger('update-template-tags');
      try {
         await updateDoc(db.templateTags, { tags });
         actionLogger.success();
      } catch (e) {
         actionLogger.failed(e);
      }
   },
};

export { templateDataAPI };
