import { makeAutoObservable } from 'mobx';
import { templateDataAPI } from '../../APIs/firebase/templateDataAPI';
import { TemplateBadge } from '../../consts/types/templates';

class SessionTemplateDataStore {
   private _tags = new Set<string>();
   private _badges: TemplateBadge[] = [];

   constructor() {
      makeAutoObservable(this, {}, { autoBind: true });
   }

   get badges(): TemplateBadge[] {
      return this._badges;
   }

   set badges(value: TemplateBadge[]) {
      this._badges = value;
   }

   get tags(): string[] {
      return Array.from(this._tags);
   }

   set tags(values: string[]) {
      this._tags = new Set(values);
   }

   initStore = () => {
      templateDataAPI.listenTags(this);
      templateDataAPI.listenBadges(this);
   };

   public addTag(tag: string) {
      this._tags.add(tag);
      templateDataAPI.updateTags(this.tags);
   }
}

export { SessionTemplateDataStore };
