import { makeAutoObservable } from 'mobx';
import { SessionTemplate } from '../../consts/types/templates';
import { sessionTemplateCategoriesAPI } from '../../APIs/firebase/sessionTemplateCategoriesAPI';
import {
   SessionTemplateCategory,
   TemplatesByCategories,
   UncategorizedTemplatesCategory,
} from '../../consts/types/categories';
import { addToArray, reorderArr } from '../../utils/array';
import { publishAPI } from '../../APIs/firebase/publishAPI';
import { SessionTemplatesStore } from '../sessionTemplates/SessionTemplatesStore';

class SessionTemplateCategoriesStore {
   private _sessionTemplateCategories: SessionTemplateCategory[] = [];

   constructor(private sessionTemplatesStore: SessionTemplatesStore) {
      makeAutoObservable(this, {}, { autoBind: true });
   }

   get sessionTemplateCategories() {
      return this._sessionTemplateCategories;
   }

   set sessionTemplateCategories(value: SessionTemplateCategory[]) {
      this._sessionTemplateCategories = value;
   }

   get sessionTemplatesByCategories(): TemplatesByCategories {
      const result: TemplatesByCategories = [];
      this._sessionTemplateCategories.forEach((category) => {
         const templates: SessionTemplate[] = [];
         category.templatesIds.forEach((id) => {
            const template = this.sessionTemplatesStore.sessionTemplates[id];
            if (!template) return;
            templates.push(template);
         });
         result.push({ category, templates });
      });
      return result;
   }

   get uncategorizedSessionTemplates(): SessionTemplate[] {
      const categorizedTemplateIds = new Set<string>();
      this._sessionTemplateCategories.forEach((category) => {
         category.templatesIds.forEach((id) => categorizedTemplateIds.add(id));
      });
      return this.sessionTemplatesStore.sessionTemplatesList.filter(
         (template) => !categorizedTemplateIds.has(template.id)
      );
   }

   initStore = () => {
      sessionTemplateCategoriesAPI.listenAll(this);
   };

   addSessionTemplateCategory = (category: Partial<SessionTemplateCategory>) => {
      if (!category.name) return;
      return sessionTemplateCategoriesAPI.add(
         category.name,
         category.isSuggested,
         category.suggestedTitle,
         this._sessionTemplateCategories.length + 1
      );
   };

   updateSessionTemplateCategory = (id: string, updates: Partial<SessionTemplateCategory>) => {
      return sessionTemplateCategoriesAPI.update(id, updates);
   };

   deleteSessionTemplateCategory = (categoryId: string) => {
      const category = this.sessionTemplateCategories.find((c) => c.id === categoryId);
      if (!category) {
         return alert('Category was not found');
      }
      if (category.templatesIds.length > 0) {
         return alert('Cannot delete category that is not empty. (have templates)');
      }
      return sessionTemplateCategoriesAPI.delete(category.id);
   };

   addTemplateToCategory = async (category: SessionTemplateCategory, templateId: string) => {
      if (category.templatesIds.includes(templateId)) {
         return alert('This template already exist in this category');
      }
      const templatesIds = [templateId, ...category.templatesIds];
      await sessionTemplateCategoriesAPI.update(category.id, { templatesIds });
   };

   deleteTemplateFromCategory = (category: SessionTemplateCategory, templateId: string) => {
      const templatesIds = category.templatesIds.filter((id) => id !== templateId);
      return sessionTemplateCategoriesAPI.update(category.id, { templatesIds });
   };

   moveTemplateToCategory = (
      templateId: string,
      sourceCategoryId: string,
      targetCategoryId: string,
      targetIndex: number
   ) => {
      const promises: Promise<void>[] = [];

      if (sourceCategoryId !== UncategorizedTemplatesCategory.id) {
         const sourceCategory = this.findSessionCategory(sourceCategoryId);
         if (!sourceCategory) {
            return alert('Source category was not found: ' + sourceCategoryId);
         }
         const sourceTemplatesIds = sourceCategory.templatesIds.filter((id) => id !== templateId);
         promises.push(
            sessionTemplateCategoriesAPI.update(sourceCategoryId, {
               templatesIds: sourceTemplatesIds,
            })
         );
      }

      if (targetCategoryId !== UncategorizedTemplatesCategory.id) {
         const targetCategory = this.findSessionCategory(targetCategoryId);
         if (!targetCategory) {
            return alert('Target category was not found: ' + sourceCategoryId);
         }
         const targetTemplatesIds = addToArray(
            targetCategory.templatesIds,
            targetIndex,
            templateId
         );
         promises.push(
            sessionTemplateCategoriesAPI.update(targetCategoryId, {
               templatesIds: targetTemplatesIds,
            })
         );
      }
      return Promise.all(promises);
   };

   reorderTemplatesInCategory = (categoryId: string, indexFrom: number, indexTo: number) => {
      const category = this.findSessionCategory(categoryId);
      if (!category) return;
      const templatesIds = reorderArr<string>(category.templatesIds, indexFrom, indexTo);
      return sessionTemplateCategoriesAPI.update(category.id, { templatesIds });
   };

   publishChanges = async () => {
      const confirmed = confirm(
         'Are you sure you want to publish? \nThis will effect all Gloww users'
      );
      if (!confirmed) return;
      try {
         await publishAPI.publishChanges();
         alert('Changes published successfully!');
      } catch (err) {
         alert('Failed to publish changes');
         console.error('Failed to publish changes', err);
      }
   };

   revertChanges = async () => {
      const confirmed = confirm(
         'Are you sure you want to revert? \nThis will delete all your work in progress'
      );
      if (!confirmed) return;
      try {
         await publishAPI.revertChanges();
         alert('Changes reverted successfully!');
      } catch (err) {
         alert('Failed to revert changes');
         console.error('Failed to revert changes', err);
      }
   };

   private findSessionCategory = (categoryId: string): SessionTemplateCategory | undefined => {
      return this.sessionTemplateCategories.find((category) => category.id === categoryId);
   };
}

export { SessionTemplateCategoriesStore };
