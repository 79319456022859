import React, { useContext, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Grid } from '@mui/material';
import { SessionTemplatesContext } from '../../../providers/sessionTemplates/SessionTemplatesProvider';
import { SessionTemplatesCategoriesContext } from '../../../providers/sessionTemplateCategories/SessionTemplateCategoriesProvider';
import { FcCategoryManagement } from '../../UI/CategoryManagement/CategoryManagement';
import { FcCategoriesWithTemplates } from '../../UI/CategoriesWithTemplates/CategoriesWithTemplates';
import { TemplateType } from '../../../consts/types/templates';
import { filterTemplates, filterTemplatesByCategories } from '../../../utils/templateUtils';
import { SessionTemplateCategory } from '../../../consts/types/categories';
import { FcAlertDialog } from '../../UI/dialogs/AlertDialog/AlertDialog';
import css from './SessionTemplatesRoute.module.scss';

type TemplateCategoryUpdates = Partial<SessionTemplateCategory>;

const FcSessionTemplatesRoute = observer(() => {
   const { sessionTemplatesList } = useContext(SessionTemplatesContext);
   const {
      sessionTemplateCategories,
      addSessionTemplateCategory,
      updateSessionTemplateCategory,
      deleteSessionTemplateCategory,
      sessionTemplatesByCategories,
      uncategorizedSessionTemplates,
      publishChanges,
      revertChanges,
      addTemplateToCategory,
      deleteTemplateFromCategory,
      moveTemplateToCategory,
      reorderTemplatesInCategory,
   } = useContext(SessionTemplatesCategoriesContext);

   const searchRef = useRef<HTMLInputElement>(null);
   const [searchFilter, setSearchFilter] = useState('');
   const [alertDialogOpen, setAlertDialogOpen] = useState(false);
   const [alertDescription, setAlertDescription] = useState<string | undefined>();
   const pendingCategoryId = useRef<string | undefined>();
   const pendingCategoryUpdates = useRef<TemplateCategoryUpdates | undefined>();

   const handleSearchChange = () => {
      if (searchRef.current) {
         setSearchFilter(searchRef.current.value.toLowerCase());
      }
   };

   const filteredTemplatesByCategories = useMemo(() => {
      return filterTemplatesByCategories(sessionTemplatesByCategories, searchFilter);
   }, [sessionTemplatesByCategories, searchFilter]);

   const filteredUncategorizedTemplates = useMemo(() => {
      return filterTemplates(uncategorizedSessionTemplates, searchFilter);
   }, [uncategorizedSessionTemplates, searchFilter]);

   const filteredAllTemplates = useMemo(() => {
      return filterTemplates(sessionTemplatesList, searchFilter);
   }, [sessionTemplatesList, searchFilter]);

   const getSuggestedCategory = (): SessionTemplateCategory | undefined => {
      return sessionTemplateCategories.find((category) => category.isSuggested);
   };

   const checkIfReplaceSuggested = (
      id: string | undefined,
      categoryUpdates: TemplateCategoryUpdates
   ): boolean => {
      if (!categoryUpdates.isSuggested) return false;
      const suggestedCategory = getSuggestedCategory();
      if (!suggestedCategory || suggestedCategory.id === id) return false;

      setAlertDescription(
         `Category ${suggestedCategory.name} is already marked as suggested for homepage. Set this category instead?`
      );
      pendingCategoryId.current = id;
      pendingCategoryUpdates.current = categoryUpdates;
      setAlertDialogOpen(true);
      return true;
   };

   const handleAddCategory = (category: Partial<SessionTemplateCategory>) => {
      if (!category.name) return;
      if (checkIfReplaceSuggested(undefined, category)) {
         return;
      }
      return addSessionTemplateCategory(category);
   };

   const handleUpdateCategory = (id: string, updates: Partial<SessionTemplateCategory>) => {
      if (checkIfReplaceSuggested(id, updates)) {
         return;
      }
      return updateSessionTemplateCategory(id, updates);
   };

   const handleConfirmReplaceSuggested = () => {
      updatePendingCategory(true);
   };

   const handleCancelReplaceSuggested = () => {
      updatePendingCategory(false);
   };

   const updatePendingCategory = async (replaceSuggested: boolean) => {
      if (pendingCategoryUpdates.current) {
         let updates: TemplateCategoryUpdates;
         if (replaceSuggested) {
            updates = pendingCategoryUpdates.current;
            const suggestedCategory = getSuggestedCategory();
            if (suggestedCategory) {
               await updateSessionTemplateCategory(suggestedCategory.id, { isSuggested: false });
            }
         } else {
            updates = { ...pendingCategoryUpdates.current, isSuggested: false };
         }

         if (pendingCategoryId.current) {
            updateSessionTemplateCategory(pendingCategoryId.current, updates);
         } else {
            addSessionTemplateCategory(updates);
         }
      }
      closeAlertDialog();
   };

   const closeAlertDialog = () => {
      setAlertDialogOpen(false);
      pendingCategoryId.current = undefined;
      pendingCategoryUpdates.current = undefined;
   };

   return (
      <div className={css.root}>
         <Grid container alignItems="center" justifyContent="space-between">
            <h1 className={css.title}>Session Templates</h1>
            <div className={css.btnGroup}>
               <input
                  ref={searchRef}
                  type="text"
                  placeholder="Search"
                  className={css.search}
                  onChange={handleSearchChange}
               />
               <Button
                  color="primary"
                  variant="contained"
                  onClick={publishChanges}
                  className={css.btn}
               >
                  Publish Changes
               </Button>
               <Button
                  color="error"
                  variant="contained"
                  onClick={revertChanges}
                  className={css.btn}
               >
                  Revert Changes
               </Button>
            </div>
         </Grid>

         <FcCategoryManagement
            type="sessionTemplate"
            categories={sessionTemplateCategories}
            addCategory={handleAddCategory}
            updateCategory={handleUpdateCategory}
            deleteCategory={deleteSessionTemplateCategory}
         />

         <FcCategoriesWithTemplates
            templatesByCategories={filteredTemplatesByCategories}
            uncategorizedTemplates={filteredUncategorizedTemplates}
            allTemplates={filteredAllTemplates}
            templateType={TemplateType.SESSION}
            addTemplateToCategory={addTemplateToCategory}
            deleteTemplateFromCategory={deleteTemplateFromCategory}
            reorderTemplatesInCategory={reorderTemplatesInCategory}
            moveTemplateToCategory={moveTemplateToCategory}
         />

         {alertDialogOpen && (
            <FcAlertDialog
               title="Change suggested category"
               description={alertDescription}
               confirmText="I'm sure"
               onConfirm={handleConfirmReplaceSuggested}
               cancelText="Cancel"
               onCancel={handleCancelReplaceSuggested}
            />
         )}
      </div>
   );
});

export { FcSessionTemplatesRoute };
