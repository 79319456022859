import { createBiLogger } from '../BI';
import { userAPI } from '../firebase/userAPI';
import { HttpMethods } from '../../consts/enums/general';
import { serverBaseUrl } from './serverBaseUrl';

const bi = createBiLogger('server API');

enum ServerType {
   BACKEND = 'backend',
}

const getRequestHeaders = async (): Promise<HeadersInit> => {
   const requestHeaders: HeadersInit = {
      'Content-Type': 'application/json; charset=UTF-8',
   };
   const authToken = await userAPI.getAuthToken();
   if (authToken) {
      requestHeaders.Authorization = 'Bearer ' + authToken;
   }
   return requestHeaders;
};

const parseResponse = async (response: Response) => {
   try {
      return await response.json();
   } catch (e) {
      return {};
   }
};

const getServerUrl = (serverType: ServerType) => {
   switch (serverType) {
      case ServerType.BACKEND:
      default:
         return serverBaseUrl;
   }
};

const serverAPI = {
   async directCall(
      path: string,
      method: HttpMethods,
      data?: Object,
      serverType = ServerType.BACKEND
   ) {
      const biAction = bi.createActionLogger(`call API: ${path}, with method: ${method}`);
      try {
         const serverUrl = `${getServerUrl(serverType)}/${path}`;
         const response = await fetch(serverUrl, {
            method,
            headers: await getRequestHeaders(),
            body: data ? JSON.stringify(data) : undefined,
         });
         if (!response.ok) {
            const error = await response.json();
            biAction.failed(error);
            return Promise.reject(error);
         }
         biAction.success();
         return await parseResponse(response);
      } catch (e) {
         biAction.failed(e);
         return Promise.reject(e);
      }
   },

   call(path: string, method: HttpMethods, data?: Object) {
      return this.directCall(`api/${path}`, method, data);
   },
};

export { serverAPI, ServerType };
