import React, { FC, useContext } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { SceneTemplate } from '../../../../../consts/types/templates';
import { SceneTemplateCategory } from '../../../../../consts/types/categories';
import FcFormButtons from '../../FormButtons/FormButtons';
import { SceneTemplatesContext } from '../../../../../providers/sceneTemplates/SceneTemplatesProvider';
import { fieldOperations, UpdateData } from '../../../../../APIs/firebase';
import FcMediaPreview from '../MediaPreview/MediaPreview';
import FcSceneTemplateFormFields from './SceneTemplateFormFields/SceneTemplateFormFields';

interface Props {
   template: SceneTemplate;
   closeDialog: () => void;
   onDeleteItem: (id: string) => void;
   category?: SceneTemplateCategory;
}

const FcSceneTemplateForm: FC<Props> = observer((props) => {
   const { template, closeDialog, onDeleteItem, category } = props;
   const { saveSceneTemplate } = useContext(SceneTemplatesContext);

   const templateValues = { ...toJS(template) };

   const formik = useFormik({
      initialValues: templateValues,
      onSubmit: async (values) => {
         const valuesToSave: UpdateData<SceneTemplate> = { ...values };
         if (!values.tags || values.tags.length === 0) {
            valuesToSave.tags = fieldOperations.delete;
         }
         if (!values.tierRestriction) {
            valuesToSave.tierRestriction = 'Free';
         }
         try {
            await saveSceneTemplate(template.id, valuesToSave);
            closeDialog();
            alert('Edit template save successfully!');
         } catch (e) {
            alert('Failed to save edit template');
         }
      },
   });

   const onDelete = () => {
      if (!category) return;
      onDeleteItem(template.id);
      closeDialog();
   };

   return (
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} autoComplete="off">
         <Grid container>
            <FcMediaPreview imgURL={formik.values.previewUrl} videoURL={formik.values.videoUrl} />
            <FcSceneTemplateFormFields formik={formik} />
         </Grid>
         <FcFormButtons onDelete={category && onDelete} deleteText="Remove from category" />
      </form>
   );
});

export { FcSceneTemplateForm };
