const colors = {
   log: '#000000',
   startAction: '#0c4597',
   actionSuccess: '#008000FF',
   startListen: '#000000',
   triggered: '#53007f',
};

const boldCss = 'font-weight: bold; text-decoration: underline;';
const getCss = (color: string, isBold = false) =>
   `background-color: white; ${isBold ? boldCss : ''} color: ${color}`;

const createBiLogger = (label: string) => ({
   log(msg: string) {
      console.debug(
         `%cFROM:%c ${label} \n%cMSG:%c ${msg}`,
         getCss(colors.log, true),
         getCss(colors.log),
         getCss(colors.log, true),
         getCss(colors.log)
      );
   },
   error(msg: string, err = '') {
      console.error(
         `%cFROM:%c ${label} \n%cMSG:%c ${msg} \n%cERROR:%c ${err}`,
         boldCss,
         '',
         boldCss,
         '',
         boldCss,
         ''
      );
   },
   createActionLogger(actionName: string) {
      console.debug(
         `%cFROM:%c ${label} \n%cSTART ACTION:%c ${actionName}`,
         getCss(colors.startAction, true),
         getCss(colors.startAction),
         getCss(colors.startAction, true),
         getCss(colors.startAction)
      );

      const timeStart = new Date().valueOf();
      return {
         success() {
            const timeEnd = new Date().valueOf();
            const totalTime = timeEnd - timeStart;
            console.debug(
               `%cFROM:%c ${label} \n%cRESOLVE ACTION:%c ${actionName} \n%cTIME:%c ${totalTime}ms`,
               getCss(colors.actionSuccess, true),
               getCss(colors.actionSuccess),
               getCss(colors.actionSuccess, true),
               getCss(colors.actionSuccess),
               getCss(colors.actionSuccess, true),
               getCss(colors.actionSuccess)
            );
         },
         failed(err: any) {
            const timeEnd = new Date().valueOf();
            const totalTime = timeEnd - timeStart;
            console.error(
               `%cFROM:%c ${label} \n%cFAILED ACTION:%c ${actionName} \n%cERROR:%c ${err} \n%cTIME:%c ${totalTime}ms`,
               boldCss,
               '',
               boldCss,
               '',
               boldCss,
               '',
               boldCss,
               ''
            );
         },
      };
   },
   createCountLogger(listenName: string) {
      console.debug(
         `%cFROM:%c ${label} \n%cSTART LISTEN TO:%c ${listenName}`,
         getCss(colors.startListen, true),
         getCss(colors.startListen),
         getCss(colors.startListen, true),
         getCss(colors.startListen)
      );
      let snapshotCounter = 0;
      return {
         triggered() {
            snapshotCounter++;
            console.debug(
               `%cFROM:%c ${label} \n%cRECEIVED A SNAPSHOT OF:%c ${listenName} \n%cSNAPSHOT COUNTER:%c ${snapshotCounter}`,
               getCss(colors.triggered, true),
               getCss(colors.triggered),
               getCss(colors.triggered, true),
               getCss(colors.triggered),
               getCss(colors.triggered, true),
               getCss(colors.triggered)
            );
         },
         error(msg: any) {
            console.error(
               `%cFROM:%c ${label} \n%cLISTENER OF:%c ${listenName} \n%cERROR:%c ${msg}`,
               boldCss,
               '',
               boldCss,
               '',
               boldCss,
               ''
            );
         },
      };
   },
});

export { createBiLogger };
