import React, { FC, memo } from 'react';
import { Dialog } from '@mui/material';
import noop from 'lodash/noop';
import {
   SceneTemplate,
   SessionTemplate,
   Template,
   TemplateType,
} from '../../../../consts/types/templates';
import { TemplateCategory } from '../../../../consts/types/categories';
import FcDialogTitle from '../DialogTitle/DialogTitle';
import { FcSessionTemplateForm } from './SessionTemplateForm/SessionTemplateForm';
import { FcSceneTemplateForm } from './SceneTemplateForm/SceneTemplateForm';

interface Props {
   template: Template;
   templateType: TemplateType;
   category?: TemplateCategory;
   open: boolean;
   toggleOpen: () => void;
   onDeleteItem?: (id: string) => void;
}
const FcSessionTemplateDialog: FC<Props> = (props) => {
   const { template, templateType, open, toggleOpen, onDeleteItem = noop, category } = props;

   return (
      <Dialog open={open} fullWidth={true} maxWidth="md" onClose={toggleOpen}>
         <FcDialogTitle text="Edit Template" onClose={toggleOpen} />
         {templateType === TemplateType.SESSION && (
            <FcSessionTemplateForm
               template={template as SessionTemplate}
               onDeleteItem={onDeleteItem}
               closeDialog={toggleOpen}
               category={category}
            />
         )}
         {templateType === TemplateType.SCENE && (
            <FcSceneTemplateForm
               template={template as SceneTemplate}
               onDeleteItem={onDeleteItem}
               closeDialog={toggleOpen}
               category={category}
            />
         )}
      </Dialog>
   );
};

export default memo(FcSessionTemplateDialog);
