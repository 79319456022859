import React, { FC } from 'react';
import css from './PremiumIndication.module.scss';

const FcPremiumIndication: FC = () => {
   return (
      <div className={css.premium}>
         <img src="/diamond.svg" alt="" />
      </div>
   );
};

export { FcPremiumIndication };
